.map-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.map-key-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(12px);
  padding: 18px 18px 8px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 16px;

  svg {
    cursor: pointer;
    margin-bottom: 18px;
  }
}
