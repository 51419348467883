.key-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.key-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    font-weight: 400;
    white-space: nowrap;
    color: #fff;
    margin-left: 10px;
    text-shadow: -0.6px -0.6px 0 #000, 0.6px -0.6px 0 #000, -0.6px 0.6px 0 #000, 0.6px 0.6px 0 #000;
  }
}
