.place-image-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: inherit;
  left: 0;
  opacity: 0;
}

.image-loaded {
  animation: fadeIn ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.place-image-container {
  position: relative;
}

.place-image-blurhash-container {
  width: 100%;
  height: 100%;
  position: absolute;
}
