.place-modal-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.place-modal-place-image {
  width: 100%;
  height: 262px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.place-modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(58, 61, 64, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.place-modal-content {
  padding: 40px;

  h2 {
    font-weight: 600;
    font-size: 26px;
    margin: 0;
  }

  h4 {
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
  }

  a,
  p,
  td {
    font-weight: 400;
    font-size: 15px;
  }
}

.place-modal-details-container {
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  gap: 50px;
}

.place-modal-details-column {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: calc(50% - 25px);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.place-modal-details-section {
  display: flex;
  flex-direction: column;
}

.place-modal-hours-table {
  width: 100%;
  color: var(--color-text-secondary);

  tr td:nth-child(2) {
    text-align: right;
  }
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.place-modal-overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.place-modal {
  width: 768px;
  max-height: calc(100vh - 25px);
  margin: 25px 0 auto 0;
  padding: 0px;
  outline: none;
  position: relative;
  overflow-y: auto;
  background: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 768px) {
    width: 100%;
    max-height: 100vh;
    margin-top: 0;
    border-radius: 0;
  }
}
