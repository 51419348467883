@import './theme.css';

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Poppins Medium'), local('Poppins-Medium'), url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Poppins, sans-serif;
  color: var(--color-text-primary);
}

a {
  color: var(--color-primary-green);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  display: inline-block;
  background: var(--color-text-primary);
  color: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
