.popup-name {
  display: block;
  margin: 4px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.popup-image {
  width: 32px;
  height: 32px;
  margin: 4px;
}

.popup-image-tooltip {
  position: absolute;
  background: white;
  transition: ease-out 0.1s;
  text-align: center;
  padding: 2px 6px;
  border-radius: 8px;
  box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.5);
  margin-top: -20px;
  color: black;

  & > p {
    font-size: 12px;
    margin: 0;
  }
}

.mapboxgl-popup-content {
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 6px 22px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}

.mapboxgl-popup-tip {
  border: 6px solid transparent;
}
